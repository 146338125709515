import api from './api'
export async function getSearchResults(search) {
    try {
        let params = {}
        if (search) params.search = search
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
            const data = await api.get(`${process.env.API_URL}/search?${query}`)
            .then(res => {
                return res.data
            })
            .catch(err => {
                console.log(err);
                return []
            })
            return data
    } catch (error) {
        return []
    }
}